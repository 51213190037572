<template>
  <div class="row">
    <div class="col-12  c g">
        <div class="card card-body">
            <div class="row">
                <div class="col-12 col-lg-2">
                    <div class="form-group">
                    <label for="">الفترة من</label>
                    <input type="date"
                        class="form-control" v-model="from" id="from">
                    </div>
                </div>
                <div class="col-12 col-lg-2">
                    <div class="form-group">
                    <label for="">الفترة الى</label>
                    <input type="date"
                        class="form-control" v-model="to" id="to">
                    </div>
                </div>
                <div class="col-12 col-lg-2">
                    <div class="form-group">
                        <label>اختر فصل معين</label>
                        <select class="form-control" v-model="classname">
                            <option :value="null">الكل</option>
                            <template v-for="classname in classes">
                                <option :value="classname.title + '-all'" :key="classname.title">{{classname.title}} - كل الفصول</option>
                                <option v-for="classroom in classname.classrooms" :key="classroom" :value="classname.title + '-' + classroom">{{classname.title}} - {{ classroom }}</option>
                            </template>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-lg-2">
                    <div class="form-group">
                    <label for="">بحث عن طالب</label>
                    <input type="text"
                        class="form-control" placeholder="ابحث هنا..." v-model="q" id="q">
                    </div>
                </div>
                <div class="col-12 col-lg-2 text-center">
                    <br>
                    <button class="btn btn-success" @click="getReport()" v-if="!loading">
                        <i class="fa fa-search"></i>
                        عرض التقرير
                    </button>
                    <button class="btn btn-primary disabled" disabled v-if="loading">
                        <i class="fa fa-search"></i>
                        جاري البحث...
                    </button>
                </div>
                <div class="col-12 row" v-if="reports.length">

                    <div class="col-12 col-lg-2">
                        <br>
                        <button @click="print()" class="btn btn-secondary btn-block">
                            <i class="fa fa-print"></i>
                            طباعة الصفحة
                        </button>
                    </div>
                    <div class="col-12 col-lg-2">
                        <br>
                        <button @click="exportExcel()" class="btn btn-success btn-block">
                            <i class="fa fa-file"></i>
                            تصدير الصفحة
                        </button>
                    </div>
                    <div class="col-12 col-lg-3">
                        <br>
                        <button  class="btn btn-primary btn-block" v-b-modal.ghiab_count>
                            <i class="fa fa-calendar"></i>
                            من تجاوز ايام غياب معينة
                        </button>
                    </div>
                    <div class="col-12 col-lg-3">
                        <br>
                        <button  class="btn btn-warning btn-block" v-b-modal.late_count>
                            <i class="fa fa-calendar"></i>
                            من تجاوز ايام تأخر معينة
                        </button>
                    </div>
                    <div class="col-12 col-lg-2">
                        <br>
                        <button  class="btn btn-info btn-block" v-b-modal.send>
                            <i class="fa fa-paper-plane"></i>
                            ارسال لولي الأمر
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="send" title="ارسال لولي الامر" hide-footer>
        <div class="form-group">
          <label for="">الرسالة <a href="javascript:;" @click="templates('asd43345')"><u><i class="fa fa-comment"></i> قوالب الرسائل</u></a></label>
          <textarea class="form-control asd43345" v-model="message" rows="6"></textarea>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-success btn-block"  @click="send('whatsapp')">
                    <i class="fa fa-whatsapp"></i>
                    واتس تلقائي
                </button>
            </div>
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-success btn-block x345" @click="send('wa')">
                    <i class="fa fa-whatsapp"></i>
                    اداة المتصفح
                </button>
            </div>
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-primary btn-block"  @click="send('sms')">
                    <i class="fa fa-mobile"></i>
                    SMS
                </button>
            </div>
        </div>
    </b-modal>
    <div class="col-12 g" v-if="!loading">
        <div class="card card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm table-striped" id="table">
                    <thead>
                        <th class="">
                            م
                        </th>
                        <th>
                            <a href="javascript:;" @click="sort = 'name'; getReport()">الطالب <i class="fa fa-sort"></i></a> 
                        </th>
                        <th>
                            
                            <a href="javascript:;" @click="sort = 'classname'; getReport()">الفصل <i class="fa fa-sort"></i></a> 
                        </th>
                        <th>
                            
                            <a href="javascript:;" @click="sort = 'status-1'; getReport()">عدد ايام الحضور <i class="fa fa-sort"></i></a> 
                        </th>
                        <th>
                            <a href="javascript:;" @click="sort = 'status-2'; getReport()">عدد ايام التأخر <i class="fa fa-sort"></i></a> 
                        </th>
                        <th>
                            عدد ايام الغياب
                        </th>
                        <th>
                            
                            <a href="javascript:;" @click="sort = 'status-5'; getReport()">عدد الغياب بعذر <i class="fa fa-sort"></i></a> 
                        </th>
                        <th>
                            
                            <a href="javascript:;" @click="sort = 'status-3'; getReport()">عدد الغياب بدون عذر <i class="fa fa-sort"></i></a> 
                        </th>
                        <th>
                            
                            <a href="javascript:;" @click="sort = 'status-ex'; getReport()">عدد ايام الاستئذان <i class="fa fa-sort"></i></a> 
                        </th>
                        <th class="hideme" v-if="!exporting">
                            عرض الكل
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(report, i) in reports" :key="report.number + report._id">
                            <td class="">
                                {{ i+1 }}
                            </td>
                            <td>
                                {{ report.name }}
                            </td>
                            <td>
                                {{ report.classname }} - {{ report.classroom }}
                            </td>
                            <td>
                                {{ report.reports['1'].length }}
                                <button class="btn text-primary btn-sm" @click="viewReports(report, '1')">
                                    <i class="fa fa-eye"></i>
                                </button>
                            </td>
                            <td>
                                {{ report.reports['2'].length }}
                                <button class="btn text-primary btn-sm" @click="viewReports(report, '2')">
                                    <i class="fa fa-eye"></i>
                                </button>
                            </td>
                            <td>
                                {{ report.reports['3'].length + report.reports['5'].length }}
                                <button class="btn text-primary btn-sm" @click="viewReports(report, '3,5')">
                                    <i class="fa fa-eye"></i>
                                </button>
                            </td>
                            <td>
                                {{ report.reports['5'].length }}
                                <button class="btn text-primary btn-sm" @click="viewReports(report, '5')">
                                    <i class="fa fa-eye"></i>
                                </button>
                            </td>
                            <td>
                                {{ report.reports['3'].length }}
                                <button class="btn text-primary btn-sm" @click="viewReports(report, '3')">
                                    <i class="fa fa-eye"></i>
                                </button>
                            </td>
                            <td>
                                {{ report.reports['ex'].length }}
                                <button class="btn text-primary btn-sm" @click="viewReports(report, 'ex')">
                                    <i class="fa fa-eye"></i>
                                </button>
                            </td>
                            <td class="hideme" v-if="!exporting">
                                <button class="btn btn-primary btn-sm" @click="viewReports(report, '1,2,3,5,ex')">
                                    <i class="fa fa-eye"></i> عرض التفاصيل
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <button id="open-alert2" v-b-modal.alert-modal2 class="dnone"></button>
    <b-modal id="alert-modal2" title="التفاصيل" hide-footer size="lg">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        التوقيت
                    </th>
                    <th>
                        الحالة
                    </th>
                    <th>
                        العذر
                    </th>
                </thead>
                <tbody>
                    <tr v-for="r in reports_details" :key="r.number + r._id">
                        <td>
                            {{ r.date }}
                        </td>
                        <td>
                            <span v-if="r.time">
                                {{ r.time.split(" ")[1] }}
                            </span>
                        </td>
                        <td>
                            <span v-if="!r.editStatus" @click="r.editStatus = !r.editStatus; refreshArr()" class="hoverme" title="اضغط للتعديل">
                            <span class="badge btn-sm bg-secondary" v-if="r.status == '0'">
                                <i class="fa fa-clock-o"></i> خارج وقت الدوام
                            </span>
                            <span class="badge btn-sm bg-success" v-if="r.status == '1'">
                                <i class="fa fa-check"></i> حاضر
                            </span>
                            <span class="badge btn-sm bg-warning" v-if="r.status == '2'">
                                <i class="fa fa-clock-o"></i> متأخر
                            </span>
                            <span class="badge btn-sm bg-danger" v-if="r.status == '3'">
                                <i class="fa fa-times"></i> غائب
                            </span>
                            <span class="badge btn-sm bg-info" v-if="r.status == '5'">
                                <i class="fa fa-times"></i> غائب بعذر
                            </span>
                            <span class="badge btn-sm bg-primary" v-if="r.status == '6'">
                                <i class="fa fa-info"></i> اجازة
                            </span>
                        </span>
                        <select v-model="r.status" v-if="r.editStatus" @change="upldateReport(r, r.time, r.date)">
                            <option value="0">خارج وقت الدوام</option>
                            <option value="1">حاضر</option>
                            <option value="2">متأخر</option>
                            <option value="3">غائب</option>
                            <option value="5">غائب بعذر</option>
                        </select>
                        </td>
                        <td>
                            {{ r.reason }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
    <b-modal id="ghiab_count" title="من تجاوز ايام غياب" hide-footer size="sm">
        <div class="form-group">
            <label for="">ايام الغياب</label>
            <input type="number"
            class="form-control" v-model="ghiab_count">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="getReport(); cc()">
                عرض التقرير
            </button>
        </div>
    </b-modal>
    <b-modal id="late_count" title="من تجاوز ايام تأخر" hide-footer size="sm">
        <div class="form-group">
            <label for="">ايام التأخر</label>
            <input type="number"
            class="form-control" v-model="late_count">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="getReport(); cc()">
                عرض التقرير
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
var XLSX = require("xlsx");
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormCheckbox } from "bootstrap-vue";
export default {
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    components: {
        // Layouts
        BModal,BFormCheckbox
    },
    data(){
        return {
            loading: false,
            reports: [],
            exporting: false,
            user: JSON.parse(localStorage.getItem('user')),
            classname: null,
            classes: [],
            sort: "nothing",
            q: null,
            from: "2024-11-17",
            to: new Date().toISOString().split("T")[0],
            reports_details: [],
            ghiab_count: null,
            late_count: null,
            message: `تقرير الفترة من {from} الى {to}
لابنكم {name}
عدد ايام الغياب: {ghiab}
عدد ايام الحضور: {attend}
عدد ايام التأخر: {late}
عدد ايام الاستئذان: {ex}`
        }
    },
    created(){
        if(!checkPer("students|reports|between")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.classes = r.response
            }
        }).fail(function(){
        })
        setInterval(function(){
            if($("#from").is(":visible")){
                g.from = $("#from").val();
                g.to = $("#to").val()
            }
        }, 100)

    },
    methods: {

        send(method){
            var g = this;
            var messages = [];
            g.reports.forEach(function(a){
                messages.push({
                    phones: a.phones,
                    message: g.message
                    .replace("{name}", a.name)
                    .replace("{from}", g.from)
                    .replace("{to}", g.to)
                    .replace("{ghiab}", a.reports['3'].length)
                    .replace("{attend}", a.reports['1'].length)
                    .replace("{late}", a.reports['2'].length)
                    .replace("{ex}", a.reports['ex'].length)
                })
            })
            if(method == 'wa'){
                $("#sendmodalwaarray").val(JSON.stringify(messages))
                $("#sendmodalwatype").val("students")
                $("#sendWAModal").click()
                    $(".x345").removeClass('btn-success')
                    $(".x345").addClass('btn-outline-danger')
                    $(".x345").html("<i class='fa fa-refresh'></i> حدث الصفحة للارسال من الاداة مرة أخرى")
            }else{
                $.post(api + '/user/messages/send-arr', {
                    jwt: g.user.jwt,
                    arr: JSON.stringify(messages),
                    method: method,
                    type: "students"
                }).then(function(r){
                    g.loaded = true;
                    if(r.status != 100){
                        alert(r.response, 200)
                    }else{
                        alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                    }
                }).fail(function(){
                    alert("حدث خطأ", 200)
                })
            }
        },
        templates(x){
            $("#templates").click()
            $("#inserttemplateto").val(x)
        },
        refreshArr(){
            this.reports_details = JSON.parse(JSON.stringify(this.reports_details))
        },
        exportExcel(){
            const wb = XLSX.utils.book_new(), g = this;
            const Heading = [
                [
                    "م",
                    "الاسم",
                    "الفصل",
                    "عدد ايام الحضور",
                    "عدد ايام التأخر",
                    "عدد ايام الغياب",
                    "عدد ايام بعذر",
                    "عدد ايام بدون عذر",
                    "عدد ايام الاستئذان"
                ]
            ];
            var xx = [];
            g.reports.forEach(function(a, i){
                var report = a;
                xx.push([
                    i+1,
                    a.name,
                    a.classname + " - " +  a.classroom,
                    report.reports['1'].length,
                    report.reports['2'].length,
                    report.reports['3'].length + report.reports['5'].length,
                    report.reports['5'].length,
                    report.reports['3'].length,
                    report.reports['ex'].length
                ])
            })
            
            // creating sheet and adding data from 2nd row of column A.
            // leaving first row to add Heading
            const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
                
            // adding heading to the first row of the created sheet.
            // sheet already have contents from above statement.
            XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
                
            // appending sheet with a name
            XLSX.utils.book_append_sheet(wb, ws, 'Records');
                
            const fileContent = XLSX.writeFile(wb, $("title").first().text().split("—") + ".xlsx");
        },
        cc(){
            $("#ghiab_count___BV_modal_header_ > button").click()
            this.ghiab_count = null;
        },
        getReport(){
            var g = this;
            g.loading = true
            $.post(api + '/user/students/reports/between', {
                jwt: this.user.jwt,
                sort: this.sort,
                classname: g.classname,
                from: g.from,
                to: g.to,
                q: this.q,
                ghiab_count: g.ghiab_count,
                late_count: g.late_count
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.reports = r.response
                }
            }).fail(function(){
                g.loading = false
            })
        },
        refreshArr(){
            this.reports = JSON.parse(JSON.stringify(this.reports))
        },
        viewReports(report, status){
            var g = this;
            status = status.split(",")
            var arr = [];
            status.forEach(function(a){
                report.reports[a].forEach(function(b){
                    arr.push(b)
                })
            })
            g.reports_details = arr;
            $("#open-alert2").click()
        },
        upldateReport(report, time, date){
            var g = this;
            $.post(api + '/user/students/reports/update-report', {
                jwt: this.user.jwt,
                report_id: report?._id != '-' ? report?._id : null,
                status: report.status,
                time: time,
                reason: report.reason,
                number: report.number,
                date: date,
                name: report.name,
                from: "manual",
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    report.editStatus = false;
                    g.refreshArr()
                    g.getReport()
                }
            }).fail(function(){
            })
        },
        print(){
            var g = this;
            var win = window.open("", "")
            win.document.write(`
            <html dir='rtl'>
                <head>
                    <style>
                        table, td, th {
                        border: 1px solid;
                        }
                        tr{
                            text-align: center
                        }
                        table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th{
                        background: #eee
                    }
                    .hideme, button{
                        display:none
                    }
                </style>
                </head>
                <body>
                    <div class='row' style='width: 100%; padding: 0px; position: relative;'>
                    <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block; position: relative; top: -10px'>
                    <h5>
                        المملكة العربية السعودية
                        <br><br>وزارة التعليم
                    </h5>
                    </div>
                    <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block'>
                        <img style='width: 100px; position: relative; top: 20px' src='https://static.arrajol.com/users/user223141/%D8%B4%D8%B9%D8%A7%D8%B1-%D9%88%D8%B2%D8%A7%D8%B1%D8%A9-%D8%A7%D9%84%D8%AA%D8%B9%D9%84%D9%8A%D9%85-%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF-%D8%B4%D9%81%D8%A7%D9%81.png'>
                        <center><h5 class='text-danger'>
                            تقرير الحضور والغياب للفترة ${g.from} الى ${g.to}
                        </h5></center>
                    </div>
                    <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block; position: relative; top: -20px'>
                    <h5>
                        ${g.user.name}
                    </h5>
                    </div>
                    ${$("#table").parent().html()}
                </body>
            </html>
            `)
            win.document.close()
            setTimeout(() => {
                win.print()
            }, 1000);
        }
    }
}
</script>

<style>

.hoverme span{
    transition: transform .2s;
}
.hoverme span:hover{
    transform: scale(1.1);
}
</style>